import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from 'react-bootstrap/Container'
import Layout from "../../components/layout"
import FeaturedImg from "../../components/featuredImg"
import Breadcrumbs from "../../components/breadcrumbs"
import pages from "../../data/pages"
import { Gallery } from "gatsby-theme-gallery"
import Card from "react-bootstrap/Card"
import Accordion from "react-bootstrap/Accordion"
import Button from "react-bootstrap/Button"
import video from "./resources1.mp4"
import './resources.scss'


export default () => {
  const data = useStaticQuery(graphql`
    query {
      featuredImgDesktop: file(
        relativePath: { eq: "20-MIM-0008_Secondary_CA-Resources-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      featuredImgMobile: file(
        relativePath: { eq: "20-MIM-0008_Mobile-13.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [photosExpanded, setPhotosExpanded] = React.useState(true);
  const [videosExpanded, setVideosExpanded] = React.useState(false);

  return (
    <Layout className="secondary-page">
      <Breadcrumbs currentItem={pages[4]}></Breadcrumbs>
      <Container className="main-content">
        <FeaturedImg data={data} title={pages[4].title}></FeaturedImg>
        <section style={{ marginBottom: "16rem" }} className="main-section">
          <article>
            <p>
              Check out our gallery of equipment photos, customer photos, and
              ‘in-the-field’ action content. Send us your photos for a chance to
              be featured!
            </p>
            <div className="row">
              <div className="col-md-12">
                <Accordion defaultActiveKey={"0"}>
                  <Card>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => { setPhotosExpanded(!photosExpanded) }}>
                      <h3>Photos&nbsp;<span className={`dropdown-toggle ${photosExpanded ? "show" : ""}`}></span></h3>
                      {photosExpanded ? null : <small>click to expand</small>}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">

                      <Gallery basePath="/gallery"></Gallery>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={() => { setVideosExpanded(!videosExpanded) }}>
                      <h3>Videos&nbsp;<span className={`dropdown-toggle ${videosExpanded ? "show" : ""}`}></span></h3>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1" style={{ textAlign: "center" }}>
                      {/* <Card.Body className="resources-gallery">
                        <iframe
                          title="video-1"
                          width="100%"
                          height="400px"
                          src="https://www.youtube.com/embed/NpEaa2P7qZI"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                        <iframe
                          title="video-2"
                          width="100%"
                          height="400px"
                          src="https://www.youtube.com/embed/NpEaa2P7qZI"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </Card.Body> */}
                      <video width="75%" height="auto" controls>
                        <source src={video} type="video/mp4" ></source>
                      </video>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
            {/* <Button style={{ width: "fit-content" }} label="SEE WHAT PEOPLE ARE SAYING!" to="/testimonials" className="smaller-text-mobile"></Button> */}
          </article>
        </section>
      </Container>
    </Layout>
  )
}
